<template>
  <div class="spam-filter-terms">
    <div class="spam-filter-value pointer" v-for="(term, index) in filterTerms" :key="index">
      <p class="p-0 m-0">{{term.value}}</p>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div width="20px" height="20px">
        <icon
            @click="handleDelete(term.id)"
            :data="deleteIcon"
            width="20"
            height="20"
            role="button"
            class="icon"
            color="#ee6123"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import deleteIcon from "@fa/solid/window-close.svg"

export default {
  name: "SpamFilterTerms",
  props: {
    searchTerms: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    terms: [],
    deleteIcon
  }),
  computed: {
    filterTerms() {
      let temp = this.searchTerms.length > 0 ? this.searchTerms : this.terms;
      return temp.filter(t=>t.value!==null);
    }
  },
  methods: {
    ...mapActions([
        'fetchAllTerms',
        'deleteTerm'
    ]),
    ...mapMutations([
      "setNotification"
    ]),
    async loadData() {
      let response = await this.fetchAllTerms().catch(err => console.log(err));
      if(response.status === 200) {
        this.terms = response.data.data;
      }
    },
    async handleDelete(id) {
      let response = await this.deleteTerm(id).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Spam Filter Deletion Error!",
          content: err.response.data.error
        });
      });

      if(response.status === 200) {
        let temp = this.$parent.searchTerm.filter(i=>i.id !== id);
        this.$parent.searchTerm = temp;
        this.terms = response.data.data;
        this.setNotification({
          show: true,
          type: "success",
          message: "Spam Filter Deleted Successfully!"
        });
      }
    }
  },
  beforeMount() {
    this.loadData();
  }
}
</script>
<style lang="scss">
.spam-filter {
  &-terms {}
  &-value {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: calc($spacingBase / 2) 20px;
    background-color: $lightergrey;
    border: 1px solid lighten($grey, 15%);
    border-radius: $spacingBase;
    margin-right: calc($spacingBase / 2);
    margin-bottom: calc($spacingBase / 2);
  }
}
</style>