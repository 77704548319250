<template>
  <div class="unnassigned-email-list">
    <div class="row">
      <div class="col-6">
        <h5 class="text-header mb-4">Unassigned Emails&nbsp;&nbsp;<span
            class="text-color-orange"
        >{{ computedData?.total }}</span></h5>
      </div>
      <div class="col-6">
        <SearchText
            label="Search unassigned emails..."
            @search-updated="handelSearch"
            @search-clear="handelSearchClear"
        />
      </div>
    </div>
    <ScrollerWrap
        id="ScrollerWrap_1"
        :next="computedData?.next_page_url"
        class="scroller-wrap -height-80 -bottom-padding-150"
        @update-list="handleLazyUpdate"
    >
      <div>
        <div
            v-for="(item, index) in computedData?.data || computedData"
            :key="index"
            class="table-row d-flex align-items-left justify-content-between p-4 border-bottom btn-show-unassigned"
        >
          <div class="text-left position-relative">
            <p><strong>Name: </strong>{{ item.from_name }}</p>
            <p><strong>Email: </strong>{{ item.from_email }}</p>
            <p><strong>Received: </strong>{{ formatReadDateTime(item.created_at) }}</p>
            <p class="d-inline-flex"><strong>Subject:&nbsp;</strong>
              <span class="link-text"
                    @click="openModal(item, index)">{{ item?.subject ? item?.subject : 'No subject available' }}</span>
            </p>
            <p
                v-if="item?.attachments?.length > 0"
                class="text-color-orange"
            >
              <strong>Number of attachments:</strong> {{ item?.attachments?.length }}
            </p>
          </div>
          <div class="d-flex align-items-center">
            <AssignEmails
                :email="item"
                :search-data="computedSearchData"
                class="btn-wrap"
                @fetch-emails="$emit('fetch-emails', true)"
            />
          </div>
        </div>
        <InfoModal ref="emailPreview">
          <template #btn-text>
          </template>
          <template #content>
            <EmailViewer
                ref="emailViewer"
                :allow-reply="false"
                :index="selectedIndex"
                :item="selectedEmailData"
            />
          </template>
        </InfoModal>
      </div>
    </ScrollerWrap>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import * as Yup from "yup";
import AssignEmails from "@/components/email-manager/AssignEmails";
import EmailViewer from "@/components/email-manager/EmailViewer";
import SearchText from "@/components/search/SearchText";

export default {
  name: "UnnassignedEmailList",
  props: {
    emailData: {
      type: Array,
      default: null
    },
  },
  data: () => ({
    searchEmailData: null,
    lazyData: null,
    selectedEmailData: null,
    selectedIndex: null
  }),
  setup() {
    const schema = Yup.object().shape({
      pension_provider: Yup.string()
          .required("required")
          .nullable()
    });
    return {
      schema
    };
  },
  components: {
    EmailViewer,
    SearchText,
    AssignEmails
  },
  computed: {
    ...mapGetters(["getProviders"]),
    computedData() {
      return this.searchEmailData || this.lazyData || this.emailData;
    }
  },
  methods: {
    ...mapActions(["searchUnnassignedEmails"]),
    handleLazyUpdate(data) {
      if (typeof data === 'undefined') return;
      this.lazyData = this.emailData
      this.lazyData.next_page_url = data.next_page_url
      this.lazyData.data = this.lazyData.data.concat(data.data)
    },
    async handelSearch(value) {
      await this.debounce(async () => {
        let response = await this.searchUnnassignedEmails({
          value
        }).catch(err => console.log(err));
        if (response.data.result.length > 0) {
          this.searchEmailData = response.data.result;
        } else {
          this.searchEmailData = null;
        }
      });
    },
    handelSearchClear() {
      console.log("clear");
    },
    openModal(item, index) {
      this.selectedEmailData = item
      this.selectedIndex = index
      this.$refs.emailPreview.handleClick()
    }
  }
};
</script>
<style lang="scss" scoped>
@include btnShow(unassigned);
.table-row {
  p {
    padding: 0;
    margin: 0;
  }
}

.unnassigned-email-list {
  overflow: hidden;
  height: 75vh;
}
</style>
