<template>
  <div>
    <h4 v-if="type === 'assign'">
      <strong
        >Assign {{ emailContent.from_name }}&#39;s (&nbsp;{{
          emailContent.from_email
        }}&nbsp;) email to...</strong
      >
    </h4>
    <div
      :class="['inbox-section', inboxStyle]"
      v-for="(ai, i) in emailDataComputed"
      :key="i"
    >
        <InboxApplication
            :ai="ai"
        />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import InboxApplication from "@/components/email-manager/InboxApplication"

export default {
  name: "Inbox",
  props: {
    searched: {
      type: Boolean,
      default: false
    },
    emailData: {
      type: Array,
      default: null
    },
    emailContent: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: "inbox"
    }
  },
  emits: ["assign-email"],
  components: {
    InboxApplication,
  },
  computed: {
    emailDataComputed() {
      if (this.emailData === null) return;

      // let temp = this.emailData.filter(i=>i.first_name !== "Spam");
      let temp = this.emailData;

      for (let i in temp) {
        let t = temp[i].traced_pension || temp[i].traced_pension_e;
        t.sort((a, b) =>
          a.email_flagged < b.email_flagged
            ? 1
            : b.email_flagged < a.email_flagged
            ? -1
            : 0
        );
      }

      for (let i in temp) {
        let t = temp[i].traced_pension || temp[i].traced_pension_e;
        t.sort((a, b) =>
            a.inbound_email_unread < b.inbound_email_unread
                ? 1
                : b.inbound_email_unread < a.inbound_email_unread
                    ? -1
                    : 0
        );
      }

      temp.sort((a, b) =>
          a.inbound_email_unread < b.inbound_email_unread
              ? 1
              : b.inbound_email_unread < a.inbound_email_unread
                  ? -1
                  : 0
      );

      temp.sort((a, b) =>
          a.email_flagged < b.email_flagged
              ? 1
              : b.email_flagged < a.email_flagged
                  ? -1
                  : 0
      );

      return temp;
    },
    inboxStyle() {
      return {
        "inbox-override": this.type === "inbox"
      };
    },
    colStyle() {
      return {
        "col-6 text-right": this.type === "assign",
        "col-3 text-left": this.type === "inbox"
      };
    }
  },
  methods: {
    ...mapActions(["assignEmail"]),
    ...mapMutations([
      "setApplicationUuid",
      "setTracedPensionUuid",
      "setEmailApplication",
    ]),
    async handleAssign(uuid) {
      // if (!confirm("Assign email to this record?")) return;
      this.$emit("assign-email", uuid);
    },
    getUnreadTotal(record) {
      return `${record.inbound_email_unread}/${record.email_count +
        record.inbound_email_count}`;
    },
    handleThreadView(auuid, tpuuid = null) {
      if (this.type === "assign") return;

      this.setApplicationUuid(auuid?.uuid);
      this.setEmailApplication(auuid);

      if(tpuuid) {
        this.setTracedPensionUuid(tpuuid?.uuid);
      }

      this.$router.push({
        name: "Email Thread Viewer",
        params: {
          uuid: tpuuid?.uuid || auuid?.uuid
        },
        query: {
          parent: tpuuid?.uuid ? 'emailtrace' : 'emailapp'
        }
      });
    },
    handleTracedPensionView(auuid, tpuuid = null) {
     this.$router.push({
        name: "Traced Pension",
        params: {
          uuid: auuid?.uuid,
          tpuuid:tpuuid?.uuid
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.inbox-override {
  .inbox-expand {
    overflow: hidden;
    max-height: 1000px;
  }
}
</style>
