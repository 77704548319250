<template>
  <div class="account-inbox-list">
    <div class="row">
      <div class="col-6">
        <h5 class="text-header mb-4">Account Inbox List&nbsp;&nbsp;<span
            class="text-color-orange"
        >{{ unread }}&nbsp;<span class="text-small text-color-darkgrey">unread</span></span></h5>
      </div>
      <div class="col-6">
        <SearchText
            label="Search applications..."
            @search-updated="handelSearch"
            @search-clear="handelSearchClear"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-3 text-left mb-3"><strong>Unread</strong></div>
      <div class="col-3 text-left mb-3">
        <icon :data="starSolid" color="orange" height="18" width="18"/>
      </div>
    </div>
    <LoadingSection :loading-state="computedDataCount">
      <ScrollerWrap
          id="ScrollerWrap_2"
          :next="computedData?.next_page_url"
          class="scroller-wrap -height-80 -bottom-padding-150"
          @update-list="handleLazyUpdate"
      >
        <Inbox :email-data="computedData?.data" :searched="searched"/>
      </ScrollerWrap>
    </LoadingSection>
  </div>
</template>
<script>
import Inbox from "@/components/email-manager/Inbox";
import LoadingSection from "@/components/loader/LoadingSection";
import starSolid from "@fa/solid/star.svg";
import {mapActions} from "vuex";

export default {
  name: "AccountInboxList",
  props: {
    emailData: {
      type: Array,
      default: null
    }
  },
  components: {
    Inbox,
    LoadingSection,
  },
  data: () => ({
    starSolid,
    searched: false,
    searchEmailData: null
  }),
  computed: {
    unread() {
      return this.emailData?.unread;
    },
    computedData() {
      return this.searchEmailData || this.emailData?.result;
    },
    computedDataCount() {
      let data = this.computedData || this.computedData?.data;
      return data;
    }

  },
  methods: {
    ...mapActions(["searchAssignedEmails"]),
    handleLazyUpdate(data) {
      if (typeof data === 'undefined') return;
      this.lazyData = this.emailData
      this.lazyData.next_page_url = data.next_page_url
      this.lazyData.data = this.lazyData.data.concat(data.data)
    },
    async handelSearch(value) {
      await this.debounce(async () => {
        let response = await this.searchAssignedEmails({value}).catch(err =>
            console.log(err)
        );
        if (typeof response?.data?.length === "undefined") {
          this.searched = true;
          this.searchEmailData = response.data;
        } else {
          this.searched = false;
          this.searchEmailData = null;
        }
      });
    },
    handelSearchClear() {
      this.searched = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.account-inbox-list {
  height: 75vh;
  overflow: hidden;
}
</style>
