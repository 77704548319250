<template>
  <div
      class="col-6 text-left inbox-body d-flex flex-column align-items-start justify-content-center"
  >
    <div v-if="ti?.current_provider === null" class="d-flex flex-column align-items-start">
      <span><strong>Employer:</strong> {{ ti?.employment[0]?.name_of_employer }}</span>
    </div>
    <div v-else class="d-flex flex-column align-items-start link-text"
         @click="$parent.$parent.handleTracedPensionView(ai, ti)">
      <span><strong>Pension:</strong> {{
          ti.user_supplied_provider_name || ti.policy_number || "Not available"
        }}</span>
      <span v-if="$parent.$parent.type === 'assign'"
            class="text-subdued"><strong>Policy number:</strong> {{ ti?.policy_number || "Not available" }}</span>
      <span v-if="$parent.$parent.type === 'assign'"
            class="text-subdued"><strong>Status:</strong> {{ ti?.status }}</span>
    </div>
  </div>
  <div
      v-if="$parent.$parent.type === 'assign'"
      :class="['inbox-header', $parent.colStyle]"
  >
    <span
        class="btn btn-success btn-wrap"
        @click="$parent.$parent.handleAssign(ti.uuid)"
    >Assign</span>
  </div>
  <div
      v-if="$parent.$parent.type === 'inbox'"
      :class="['inbox-body', $parent.colStyle]"
  >
    <span v-if="ti?.inbound_email_unread !== 0" class="text-color-orange">
      <strong>{{ $parent.$parent.getUnreadTotal(ti) }}</strong>
    </span>
    <span v-else class="text-color-orange">
      {{ $parent.$parent.getUnreadTotal(ti) }}
    </span>
  </div>
  <div
      v-if="$parent.$parent.type === 'inbox'"
      class="col-3 text-left inbox-body"
  >
    <span v-if="ti?.email_flagged !== 0" class="text-color-orange">
      <strong>{{ ti.email_flagged }}</strong>
    </span>
    <span v-else class="text-color-orange">
      {{ ti.email_flagged }}
    </span>
    <div class="btn-sm btn-success view-thread pointer btn-wrap" @click="$parent.$parent.handleThreadView(ai, ti)">
      view
    </div>
  </div>
</template>
<script>
export default {
  name: "InboxPension",
  props: {
    ai: {
      type: Object,
      default: null
    },
    ti: {
      type: Object,
      default: null
    },
  },
}
</script>
<style lang="scss">
.inbox-body-row {
  &:hover {
    background-color: $lightergrey;
  }
}

.view-thread {
  position: absolute;
  right: 20px;
}
</style>