<template>
  <div class="row btn-show-inbox">
    <div
        class="col-6 text-left inbox-header pointer"
        @click.prevent="handleShow"
    >
      <div class="expand-control-wrap" v-if="tpLength">
        <icon v-if="show" :data="arrowDown" width="26" height="26" dir="down" color="#61b3de" />
        <icon v-else :data="arrowDown" width="26" height="26" dir="up" color="#61b3de" />
      </div>
      <PopOver v-if="ai?.assigned_user?.length > 0" :content="'Owner: '+ai.assigned_user[0].first_name">
        <span class="link-text"
        ><strong>Application:</strong> {{ ai.first_name }} {{ ai.last_name }}</span
        >
      </PopOver>
      <span v-else><strong>Application:</strong> {{ ai.first_name }} {{ ai.last_name }}</span>
    </div>
    <div
        v-if="$parent.type === 'assign'"
        :class="['inbox-header', colStyle]"
    >
      <span class="btn btn-success btn-wrap" @click="$parent.handleAssign(ai.uuid)"
      >Assign</span>
    </div>

    <div
        v-if="$parent.type === 'inbox'"
        :class="['inbox-header', colStyle]"
    >
      <span class="text-color-orange" v-if="ai?.inbound_email_unread !== 0">
        <strong>{{ $parent.getUnreadTotal(ai) }}</strong>
      </span>
      <span class="text-color-orange" v-else>
        {{ $parent.getUnreadTotal(ai) }}
      </span>
    </div>
    <div
        v-if="$parent.type === 'inbox'"
        class="col-3 text-left inbox-header"
    >
      <span class="text-color-orange" v-if="ai?.email_flagged !== 0">
        <strong>{{ ai.email_flagged }}</strong>
      </span>
      <span class="text-color-orange" v-else>
        {{ ai.email_flagged }}
      </span>
      <div class="btn-sm btn-success view-thread pointer btn-wrap" @click="$parent.handleThreadView(ai)">view</div>
    </div>
  </div>
  <div :class="['inbox-expand', showHide]">
    <div
        class="row inbox-body-row btn-show-inbox"
        v-for="(ti, j) in filterReadTracedPensionEmails(ai)"
        :key="j"
    >
      <InboxPension
          :ai="ai"
          :ti="ti"
      />
    </div>
  </div>
</template>
<script>
import InboxPension from "@/components/email-manager/InboxPension"
import arrowDown from "@fa/solid/arrow-alt-circle-down.svg";

export default {
  name: "InboxApplication",
  props: {
    ai: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    show: false,
    tp: [],
    arrowDown
  }),
  components: {
    InboxPension,
  },
  computed: {
    showHide() {
      return {
        'd-none': !this.show,
        'd-block': this.show,
      }
    },
    colStyle() {
      return {
        "col-6 text-right": this.$parent.type === "assign",
        "col-3 text-left": this.$parent.type === "inbox"
      };
    },
    tpLength() {
      return this.tp.length > 0;
    }
  },
  methods: {
    filterReadTracedPensionEmails(tpv) {
      this.tp = tpv.traced_pension || tpv.traced_pension_e

      // if(this.type == 'assign' || this.searched) {
      return this.tp;
      // }

      // let tp1 = tp.filter(t=>t.inbound_email_unread !== 0)
      // let tp2 = tp.filter(t=>t.email_flagged !== 0);
      //
      // return tp1.concat(tp2);

    },
    handleShow() {
      this.show = !this.show;
    },
  },
}
</script>
<style lang="scss">
@include btnShow(inbox);
.expand-control-wrap {
  width: 30px;
  margin-right: 10px;
}
.btn-show-inbox {
  &:hover {
    background-color: $lightergrey;
  }
}
.view-thread {
  position: absolute;
  right: 20px;
}
</style>