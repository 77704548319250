<template>
  <div>
    <InfoModal class="mod-assign" ref="emailPreview" @doaction="handleAction">
      <template #btn-text>
        <span class="btn btn-success">Assign&nbsp;to...</span>
      </template>
      <template #content>
        <Form
          class="w-100 text-left p-0"
          @submit="saveForm"
          :validation-schema="schema"
          autocomplete="off"
        >
          <div v-if="step == 'search'">
            <SearchText
                @search-updated="handelSearch"
                @search-clear="handelSearchClear"
                label="Search applications..."
            >
              <template #search-results>
                <div class="search-results-wrap">
                  <div class="search-results-inner" v-for="(s, i) in searchResults" :key="i" @click="handleFuzzySelect(s)">
                    <span>{{s.first_name}} {{s.last_name}}</span>
                    <span v-html="getOwner(s.assigned_user)"></span>
                  </div>
                </div>
              </template>
            </SearchText>
          </div>
          <div v-if="step == 'select'">
            <Close @close.prevent="handleBack">
              back
            </Close>
            <div class="row">
              <div class="col-12 mt-4">
                <Inbox
                  :email-data="selectedData"
                  :email-content="email"
                  @assign-email="handleAssign"
                  type="assign"
                />
              </div>
            </div>
          </div>
        </Form>
      </template>
    </InfoModal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as Yup from "yup";
import Inbox from "@/components/email-manager/Inbox";

export default {
  name: "AssignEmails",
  props: {
    email: {
      type: Object,
      default: null
    }
  },
  emits: ["fetch-emails"],
  data: () => ({
    step: "search",
    selectedData: [],
    searchResults: []
  }),
  setup() {
    const schema = Yup.object().shape({
      pension_provider: Yup.string()
        .required("required")
        .nullable()
    });
    return {
      schema
    };
  },
  components: {
    Inbox
  },
  methods: {
    ...mapActions([
        "assignEmail",
        "fetchAllAccountInbox",
    ]),
    getOwner(owner) {
      if(owner.length === 0) return "";
      return '<strong>Owner: </strong>' + owner[0].first_name + ' ' + owner[0].last_name;
    },
    handelSearchClear() {
      this.searchResults = [];
    },
    async handelSearch(search) {
      if(search == "") return;
      let response = await this.fetchAllAccountInbox({search}).catch(err =>
          console.log(err)
      );
      if(response.status === 200) {
        this.searchResults = response.data;
      }
    },
    async handleAssign(uuid) {
      await this.assignEmail({ uuid, emailId: this.email.id }).catch(err =>
        console.log(err)
      );
      this.$refs.emailPreview.show = false;
      this.$emit("fetch-emails");
    },
    handleBack() {
      this.step = "search";
    },
    saveForm() {
      console.log("save form");
    },
    handleFuzzySelect(e) {
      this.selectedData = [];
      this.selectedData.push(e);
      this.step = "select";
    },
    handleAction() {
      this.step = "search";
    }
  }
};
</script>
<style lang="scss" scoped></style>
