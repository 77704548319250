<template>
  <div class="spam-filter">
    <div class="d-flex flex-row justify-content-between">
      <div>
        <h4 class="mb-2">Add value to filter</h4>
        <p class="link-text" @click.prevent="handleViewTerms" v-if="stateComp === 'input'">View values</p>
        <p class="link-text" @click.prevent="handleViewTerms" v-if="stateComp === 'terms'">Add values</p>
        <div v-if="stateComp === 'input'">
          <p>You can add multiple values by separating each with a "|" ( pipe ) symbol.<br>
          <strong>For example:</strong> value 1|value 2|value 3 <br>
          <strong>or:</strong> value 1 | value 2 | value 3</p>
          <p>You can provide a filter for the following:</p>
          <ul>
            <li>Email address</li>
            <li>From name</li>
            <li>Subject</li>
          </ul>
          <p>Please do not include the title for each section when providing the filter value. <br>
          <strong>For example:</strong> <br>
            do not add "Name: Aviva Pension Tracing Service"<br>
            instead add "Aviva Pension Tracing Service"
          </p>
        </div>
      </div>
      <SearchText
        v-if="stateComp === 'terms'"
        @search-updated="handelSearch"
        @search-clear="handelSearchClear"
        label="Search..."
      />
    </div>
    <Form class="w-100 text-left" @submit="saveFilter" v-if="stateComp === 'input'" :validation-schema="schema" ref="spamForm">
      <TextInput
          ref="spamValue"
          name="value"
          type="text"
      />
      <button class="fabric-btn fabric-btn-submit">Save</button>
    </Form>
    <SpamFilterTerms
        v-if="stateComp === 'terms'"
        :search-terms="searchTerm"
    />
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import SpamFilterTerms from "@/components/email-manager/SpamFilterTerms";
import * as Yup from "yup";

export default {
  name: "SpamFilter",
  props: {
    preState: {
      type: String,
      default: null
    },
  },
  data: () => ({
    state: 'input',
    searchTerm: []
  }),
  components: {
    SpamFilterTerms,
  },
  setup() {
    const schema = Yup.object().shape({
      value: Yup.string().required(),
    });
    return {
      schema
    };
  },
  computed: {
    stateComp() {
      return this.state;
    }
  },
  methods: {
    ...mapActions([
       'saveTerm',
       'searchFilter',
    ]),
    ...mapMutations([
        "setNotification"
    ]),
    async handelSearch(value) {
      let response = await this.searchFilter({ search: value }).catch(err=>console.log(err));
      if(response.status == 200) {
        this.searchTerm = response.data.data;
      }
    },
    async saveFilter(params) {
      let response = await this.saveTerm(params).catch(err =>{
        this.setNotification({
          show: true,
          type: "error",
          message: "Spam Filter Error!",
          content: err.response.data.error
        });
      });

      if(response.status == 200) {
        this.$refs.spamForm.resetForm();
        this.setNotification({
          show: true,
          type: "success",
          message: "Spam Filter Updated!"
        });
      }
    },
    handleViewTerms() {
      this.state = this.state == 'terms' ? 'input' : 'terms';
    }
  },
  beforeMount() {
    if(this.preState) {
      this.state = this.preState;
    }
  }
}
</script>
<style lang="scss">
.spam-filter {
  min-width: 600px;
}
</style>