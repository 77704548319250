<template>
  <div class="container-fluid h-auto">
    <BreadCrumb
        :links="[
      {
        name: 'Email Manager'
      }
    ]"
    />
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body text-left">
            <ul class="nav float-right">
              <li class="nav-item mr-3 d-inline-flex align-items-center" @click="handleRuleClick">
                <icon
                    :data="branch"
                    class="icon"
                    height="15"
                    role="button"
                    width="15"
                />&nbsp;&nbsp;
                <span class="link-text">Email rule manager</span>
              </li>
              <li class="nav-item mr-3 d-inline-flex align-items-center">
                <icon
                    :data="add"
                    class="icon"
                    height="15"
                    role="button"
                    width="15"
                />&nbsp;&nbsp;
                <InfoModal ref="spamFilter">
                  <template #btn-text>
                    Update spam filter
                  </template>
                  <template #content>
                    <SpamFilter/>
                  </template>
                </InfoModal>
              </li>
              <li class="nav-item d-inline-flex align-items-center">
                <icon
                    :data="eye"
                    class="icon"
                    height="15"
                    role="button"
                    width="15"
                />&nbsp;&nbsp;
                <InfoModal ref="spamFilter">
                  <template #btn-text>
                    View spam filter
                  </template>
                  <template #content>
                    <SpamFilter pre-state="terms"/>
                  </template>
                </InfoModal>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card mb-4">
          <div class="card-body">
            <UnnassignedEmailList
                :email-data="emailResponse?.data"
                @fetch-emails="fetchEmails"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card mb-4">
          <div class="card-body">
            <AccountInboxList
                :email-data="accountEmail?.data"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UnnassignedEmailList from "@/components/lists/UnnassignedEmailList";
import AccountInboxList from "@/components/lists/AccountInboxList";
import SpamFilter from "@/components/email-manager/SpamFilter";
import add from "@fa/solid/plus-circle.svg";
import eye from "@fa/regular/eye.svg";
import branch from "@fa/solid/code-branch.svg";

export default {
  name: "EmailManager",
  data: () => ({
    allAccounts: null,
    emailResponse: null,
    accountEmail: null,
    add,
    eye,
    branch,
  }),
  watch: {
    getPusherNotification(param) {
      switch (param.type) {
        case 'email-received':
          this.loadUnnassignedEmails();
          break;
      }
    }
  },
  components: {
    UnnassignedEmailList,
    SpamFilter,
    AccountInboxList
  },
  computed: {
    ...mapGetters([
      'getPusherNotification',
      'getEmailResponse',
      'getAccountEmail',
    ])
  },
  methods: {
    ...mapMutations([
      'setEmailResponse',
      'setAccountEmail',
    ]),
    ...mapActions([
      "fetchUnnassignedEmails",
      "fetchAccountInbox"
    ]),
    handleRuleClick() {
      this.$router.push({
        name: "Email Scheduler"
      })
    },
    async fetchEmails(deep = false) {
      this.loadUnnassignedEmails();
      if (deep === true) {
        this.accountEmail = null;
        await this.loadAccountEmails();
        this.setAccountEmail(this.accountEmail);
        return;
      }

      if (this.getAccountEmail === null) {
        await this.loadAccountEmails();
        this.setAccountEmail(this.accountEmail);
      } else {
        this.accountEmail = this.getAccountEmail;
      }
    }
  },
  async beforeMount() {
    await this.fetchEmails();
  }
};
</script>
<style lang="scss" scoped></style>
